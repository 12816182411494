export { LauncherTypes, LauncherTypesType, LauncherAlignment, LauncherAlignmentType };

// TODO: use generated types
enum LauncherTypes {
  WINDOW = 'WINDOW',
  IFRAME = 'IFRAME',
  GROW_IFRAME = 'GROW_IFRAME',
  FOLDER = 'FOLDER',
  PROFILE = 'PROFILE',
  POPUP = 'POPUP',
}

enum LauncherAlignment {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
}

type LauncherTypesType = keyof typeof LauncherTypes;

type LauncherAlignmentType = keyof typeof LauncherAlignment;
