import { AppSwitch, Icons } from 'src/app/shared/constants';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService, ContextService, ContextStateService, ThemeService } from '../../shared/services';
import { Router } from '@angular/router';
import { DxMenuComponent } from 'devextreme-angular';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DEFAULT_AVATAR } from '../../../magic';
import { AuthService, ScreenService } from 'grow-angular/services';
import { AppContext } from 'src/app/shared/contracts';
import { ContextsQuery } from 'src/app/members.api.g';

type Context = ContextsQuery['context']['onlyWithLaunchers']['nodes'][0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(DxMenuComponent, { static: false }) menu: DxMenuComponent;
  userInfo: any;
  userName: string;
  isMobile$: Observable<boolean> = this.screenService.isMobile$;
  dropDownOptions;
  userAvatar = DEFAULT_AVATAR;
  icons = Icons;
  productSwitchIcon: string;
  contexts: Context[] = [];
  currentContext: AppContext;
  sub$: Subscription[] = [];
  tertiaryMenuItems: any;

  constructor(
    private screenService: ScreenService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private contextService: ContextService,
    private contextState: ContextStateService,
    private themeService: ThemeService
  ) {
    this.dropDownOptions = {
      shading: false,
      width: '300px',
      position: { my: { x: 'right', y: 'top' }, at: { x: 'right', y: 'top' }, offset: { y: '59' } },
    };
  }

  ngOnInit(): void {
    this.productSwitchIcon = `https://its-deployment.bitmedia.at/data/grow-icons/feather/${this.themeService.getThemeName()}/app-switch.svg`;
    this.sub$.push(
      this.userService
        .getCurrentUser()
        .pipe(filter((user) => user.id !== null))
        .subscribe((user) => {
          this.userName = user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : user.name;
          this.userAvatar = user.imageBase64 ? user.imageBase64 : DEFAULT_AVATAR;
          this.userInfo = [
            {
              value: '1',
              aria: 'PROFILE',
              name: this.userName,
              src: this.userAvatar,
              role: user.roles,
              class: 'user-info dx-theme-border-color',
              click: () => {
                this.router.navigate(['/profile']);
              },
            },
            {
              value: '2',
              aria: 'logout',
              name: 'logout',
              click: () => {
                this.logOut();
              },
            },
          ];
        }),
      this.contextState.contexts.subscribe((contexts) => {
        this.contexts = contexts;
      }),
      this.contextState.currentContext.subscribe((context) => {
        this.currentContext = context;
        this.tertiaryMenuItems = context.menuItems.tertiary;
      })
    );
  }

  selectContext(context: Context) {
    if (context.id === this.currentContext.id) {
      return;
    }
    this.sub$.push(
      this.contextService.getContext(context.id).subscribe((appContext) => {
        this.contextState.setCurrentContext(appContext);
      })
    );
  }

  onItemClick(event) {
    event?.itemData?.click?.call();
  }

  logOut() {
    const iframe = document.querySelector('iframe.application-frame') as HTMLIFrameElement;
    if (iframe) {
      const origin = new URL(iframe.src).origin;
      const appWindow = iframe.contentWindow;
      appWindow.postMessage({ type: 'log_out' }, origin);
    }
    this.authService.logOut();
  }

  ngOnDestroy() {
    this.sub$.forEach((item) => item.unsubscribe());
  }
}
