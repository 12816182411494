import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * 
   * Represents a duration of time (see 'j' pattern https://nodatime.org/3.0.x/userguide/duration-patterns)
   * 
   * The format is:  -H:mm:ss.FFFFFFFFF
   * 
   * H - total hours (can be >=24)
   * mm - minutes (0-59)
   * ss - seconds (0-59)
   * FFFFFFFF - fractions of a second
   *
   */
  Duration: { input: any; output: any; }
  /**
   *
   * A UTC timestamp in ISO 8601 format (invariant culture). May includes fractions of seconds.
   *
   * Examples:
   *
   * - `2020-12-24T16:23:44,0003Z`
   *
   */
  Instant: { input: any; output: any; }
  /**
   *
   * Keys must conform to the regular expression `/^[A-Z][_A-Z0-9]*$/`
   *
   */
  Key: { input: any; output: any; }
  /**
   *
   * A local date without timezone, in ISO 8601 format (yyyy-mm-dd).
   *
   * Examples:
   *
   * - `2020-12-24`
   * - `2020-01-01`
   *
   */
  LocalDate: { input: any; output: any; }
  /**
   *
   * A local date and time without timezone, in ISO 8601 format (yyyy-mm-dd HH:MM:SS).
   *
   * Examples:
   *
   * - `2020-12-24T13:00:24`
   * - `2020-01-01`
   *
   */
  LocalDateTime: { input: any; output: any; }
  /**
   *
   * A local time without timezone or date, in 24 hour format.
   *
   * Examples:
   *
   * - `00:43:23`
   * - `07:55:30`
   * - `18:30:20`
   *
   */
  LocalTime: { input: any; output: any; }
  /**
   * 
   * Represents a period of time expressed in human chronological terms: hours, days, weeks, months and so on.
   * 
   * The format is: P (date components) T (time components)
   * 
   * Where each non-zero component within the period is specified as its value followed by a unit specifier from this list:
   * 
   * - Y (years)
   * - M (months in the date portion)
   * - W (weeks)
   * - D (days)
   * - H (hours)
   * - M (minutes in the date portion)
   * - S (seconds)
   * - s (milliseconds)
   * - t (ticks)
   *
   */
  Period: { input: any; output: any; }
  /**
   *
   * UUID in all lowercase letters, with '-' separated for readability. Example: ac1ac624-4b13-4dfb-9cb3-0d84ba7e5767
   *
   */
  UUID: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
  Url: { input: any; output: any; }
  Uuid: { input: any; output: any; }
};

export enum AppShellLaunchType {
  Folder = 'FOLDER',
  GrowIframe = 'GROW_IFRAME',
  Iframe = 'IFRAME',
  Popup = 'POPUP',
  Profile = 'PROFILE',
  Window = 'WINDOW'
}

export enum AppShellLauncherAlignment {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIARY'
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type AuthorizationToken = {
  __typename?: 'AuthorizationToken';
  /** URL to be used to assign token */
  assignmentUrl: Scalars['String']['output'];
  /** Amount of token activations were made */
  countUsed: Scalars['Int']['output'];
  /** Custom E-Mail Body (the HTML part) */
  emailBodyHtml: Scalars['String']['output'];
  /** Custom E-Mail Body (the Plain-Text part) */
  emailBodyText: Scalars['String']['output'];
  /** Custom E-Mail Subject */
  emailSubject: Scalars['String']['output'];
  /** URL to be used to assign token */
  emails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  expirationDate: Scalars['LocalDate']['output'];
  expirationTime?: Maybe<Scalars['LocalTime']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  key: Scalars['Key']['output'];
  /** Members assigned to current token */
  members?: Maybe<Array<Maybe<Member>>>;
  payload: Scalars['String']['output'];
  /** URL user to be redirected after token assigned */
  redirectionUrl: Scalars['String']['output'];
  /** Token type */
  tokenType: AuthorizationTokenType;
  /** URL to be used to assign token. Value -1 means there is no limitation */
  usageLimit: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
};

export type AuthorizationTokenConnectionGql = {
  __typename?: 'AuthorizationTokenConnectionGQL';
  edges: Array<AuthorizationTokenEdgeGql>;
  nodes: Array<AuthorizationToken>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AuthorizationTokenEdgeGql = {
  __typename?: 'AuthorizationTokenEdgeGQL';
  cursor: Scalars['String']['output'];
  node: AuthorizationToken;
};

export type AuthorizationTokenMutation = {
  __typename?: 'AuthorizationTokenMutation';
  remove: Scalars['Boolean']['output'];
  resendEmail: AuthorizationToken;
  upsert: AuthorizationToken;
};


export type AuthorizationTokenMutationRemoveArgs = {
  input: UpsertRef2Input;
};


export type AuthorizationTokenMutationResendEmailArgs = {
  input: UpsertAuthorizationTokenInput;
};


export type AuthorizationTokenMutationUpsertArgs = {
  input: UpsertAuthorizationTokenInput;
};

export enum AuthorizationTokenType {
  /** Assignment token with list of roles. */
  Default = 'DEFAULT',
  /** Personified assignment token with list of roles. */
  Personal = 'PERSONAL',
  /** Self registration assignment token */
  Selfregistration = 'SELFREGISTRATION'
}

export type CompositeRoleInput = {
  client?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type Context = {
  __typename?: 'Context';
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  key: Scalars['Key']['output'];
  label: LangString;
  /** Returns launchers for current Context. If `all` is true method will return all launchers otherwise only available due to user roles */
  launchers: Array<Maybe<Launcher>>;
  order?: Maybe<Scalars['Int']['output']>;
  uuid: Scalars['UUID']['output'];
};


export type ContextLaunchersArgs = {
  all?: Scalars['Boolean']['input'];
};

export type ContextConnectionGql = {
  __typename?: 'ContextConnectionGQL';
  edges: Array<ContextEdgeGql>;
  nodes: Array<Context>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ContextEdgeGql = {
  __typename?: 'ContextEdgeGQL';
  cursor: Scalars['String']['output'];
  node: Context;
};

export type ContextMutation = {
  __typename?: 'ContextMutation';
  remove: Scalars['Boolean']['output'];
  upsert: Context;
};


export type ContextMutationRemoveArgs = {
  input: UpsertRef2Input;
};


export type ContextMutationUpsertArgs = {
  input: UpsertContextInput;
};

export enum EmailTemplateType {
  ResetPassword = 'RESET_PASSWORD',
  UserInvitation = 'USER_INVITATION'
}

/** Represents a file that was uploaded */
export type File = {
  __typename?: 'File';
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['Key']['output'];
  url: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

/** You can specify either a id, key or uuid, or let the system guess your value. Will be changed to `oneOf` type once it is in the GraphQL Spec */
export type IdOrKeyInput = {
  guess?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['Key']['input']>;
  /**
   * 
   * The key will be normalized to a valid key - it will be made uppercase, and non-letters will be 
   * converted to underscore. Consecutive, trailing and leading underscores will be ommitted.
   *             
   * Example: `john.doe@bitmedia.at` => `JOHN_DOE_BITMEDIA_AT`
   */
  laxKey?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['Uuid']['input']>;
};

export type KeyValueInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type KeycloakMutation = {
  __typename?: 'KeycloakMutation';
  sync?: Maybe<Scalars['String']['output']>;
};


export type KeycloakMutationSyncArgs = {
  hosts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type KeycloakThemeSettingsGql = {
  __typename?: 'KeycloakThemeSettingsGQL';
  bottomIFrameURL?: Maybe<Scalars['String']['output']>;
  customCssURL?: Maybe<Scalars['String']['output']>;
  /** Keycloak login theme. */
  name?: Maybe<Scalars['String']['output']>;
  /** Parent theme name. */
  parent?: Maybe<Scalars['String']['output']>;
  /** Side IFrame URL. */
  sideIFrameURL?: Maybe<Scalars['String']['output']>;
  topIFrameURL?: Maybe<Scalars['String']['output']>;
};

/**
 *
 * A localized or translatable text. To get the Neutral value (untranslated), a shortcut to 'neutral' is available.
 * To get the text that matches the current users preferences, use 'value'.
 *
 */
export type LangString = {
  __typename?: 'LangString';
  id: Scalars['ID']['output'];
  /** The neutral (untranslated) text that is used when no language specific override was found */
  neutral: Scalars['String']['output'];
  /** The localized text matching the users (or systems) preferences */
  value: Scalars['String']['output'];
  /** Localized values */
  values: Array<LangStringValue>;
};

export type LangStringValue = {
  __typename?: 'LangStringValue';
  id: Scalars['ID']['output'];
  /**
   *
   * The ISO 639 code.
   *
   * Example:
   * * de-AT
   * * en
   * * x-none
   *
   * :::note
   *
   * `x-none` is the default value
   *
   * :::
   *
   */
  locale: Scalars['String']['output'];
  /** The localized text */
  value: Scalars['String']['output'];
};

export type Launcher = {
  __typename?: 'Launcher';
  additionalConfiguration: Scalars['String']['output'];
  alignment: AppShellLauncherAlignment;
  baseUrl: Scalars['String']['output'];
  context: Context;
  cssClasses: Scalars['String']['output'];
  hideForRoles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  isHidden: Scalars['Boolean']['output'];
  key: Scalars['Key']['output'];
  label: LangString;
  order?: Maybe<Scalars['Int']['output']>;
  path: Scalars['String']['output'];
  showForRoles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: AppShellLaunchType;
  uuid: Scalars['UUID']['output'];
};

export type LauncherConnectionGql = {
  __typename?: 'LauncherConnectionGQL';
  edges: Array<LauncherEdgeGql>;
  nodes: Array<Launcher>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LauncherEdgeGql = {
  __typename?: 'LauncherEdgeGQL';
  cursor: Scalars['String']['output'];
  node: Launcher;
};

export type LauncherMutation = {
  __typename?: 'LauncherMutation';
  changeLauncherHiddenFlag: Scalars['Boolean']['output'];
  remove: Scalars['Boolean']['output'];
  upsert: Launcher;
};


export type LauncherMutationChangeLauncherHiddenFlagArgs = {
  input: LauncherStateChangeInput;
};


export type LauncherMutationRemoveArgs = {
  input: UpsertRef2Input;
};


export type LauncherMutationUpsertArgs = {
  input: UpsertLauncherInput;
};

export type LauncherStateChangeInput = {
  isHidden: Scalars['Boolean']['input'];
  launcherPaths: Array<Scalars['String']['input']>;
};

export type MailTemplateMutation = {
  __typename?: 'MailTemplateMutation';
  upsert: Mailtemplate;
};


export type MailTemplateMutationUpsertArgs = {
  input: UpsertMailInput;
};

/** A mail template */
export type Mailtemplate = {
  __typename?: 'Mailtemplate';
  /** Additional information */
  additionalInformation?: Maybe<LangString>;
  id: Scalars['ID']['output'];
  key: Scalars['Key']['output'];
  /** Main text */
  mainText?: Maybe<LangString>;
  /** Metadata */
  metadata?: Maybe<Scalars['String']['output']>;
  /** Defines name of the mail template */
  name: Scalars['String']['output'];
  /** Subject */
  subject?: Maybe<LangString>;
  /** Type */
  type: EmailTemplateType;
  uuid: Scalars['UUID']['output'];
};

export type Member = {
  __typename?: 'Member';
  /** Email that is used for communication */
  contactEmail?: Maybe<Scalars['String']['output']>;
  /** User prefered date format. */
  dateFormat?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  externalId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageBase64: Scalars['String']['output'];
  imageFile?: Maybe<File>;
  key: Scalars['Key']['output'];
  /** Email that is registered for person in keycloak */
  keycloakEmail?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  /** User prefered locale. */
  locale?: Maybe<Scalars['String']['output']>;
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  roles: Array<Maybe<RoleGql>>;
  /** User prefered theme. */
  theme?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type MemberConnectionGql = {
  __typename?: 'MemberConnectionGQL';
  edges: Array<MemberEdgeGql>;
  nodes: Array<Member>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MemberEdgeGql = {
  __typename?: 'MemberEdgeGQL';
  cursor: Scalars['String']['output'];
  node: Member;
};

export type MemberMutation = {
  __typename?: 'MemberMutation';
  addRole: Scalars['Boolean']['output'];
  remove: Scalars['Boolean']['output'];
  removeRole: Scalars['Boolean']['output'];
  setUserPassword: Scalars['Boolean']['output'];
  upsert: Member;
};


export type MemberMutationAddRoleArgs = {
  clientName?: InputMaybe<Scalars['String']['input']>;
  idOrKey: IdOrKeyInput;
  roleName: Scalars['String']['input'];
};


export type MemberMutationRemoveArgs = {
  input: UpsertRef2Input;
};


export type MemberMutationRemoveRoleArgs = {
  clientName?: InputMaybe<Scalars['String']['input']>;
  idOrKey: IdOrKeyInput;
  roleName: Scalars['String']['input'];
};


export type MemberMutationSetUserPasswordArgs = {
  idOrKey: IdOrKeyInput;
  password?: InputMaybe<Scalars['String']['input']>;
  temporary: Scalars['Boolean']['input'];
};


export type MemberMutationUpsertArgs = {
  input: UpsertMemberInput;
};

export type Mutation = {
  __typename?: 'Mutation';
  authorizationToken?: Maybe<AuthorizationTokenMutation>;
  context?: Maybe<ContextMutation>;
  keycloak?: Maybe<KeycloakMutation>;
  launcher?: Maybe<LauncherMutation>;
  mailTemplate?: Maybe<MailTemplateMutation>;
  member?: Maybe<MemberMutation>;
  /** Generate a TOKEN, that can be used for granting privileges. Tokens are 8 characters long, matching the regular expression `/^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{2}$/` */
  newToken: Scalars['String']['output'];
  /** Generate fresh UUIDs */
  newUUID: Array<Scalars['UUID']['output']>;
  role?: Maybe<RoleMutation>;
  /** Send e-Mail now */
  sendEmail: Scalars['String']['output'];
  settings?: Maybe<SettingsMutation>;
};


export type MutationNewUuidArgs = {
  count: Scalars['Int']['input'];
};


export type MutationSendEmailArgs = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  from?: InputMaybe<Scalars['String']['input']>;
  htmlBody?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  textBody: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};

export enum OwnProfileAccessEnum {
  Default = 'DEFAULT',
  Edit = 'EDIT',
  Hidden = 'HIDDEN',
  View = 'VIEW'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  authorizationTokens: QueryAuthorizationTokensGql;
  context: QueryContextsGql;
  launchers: QueryLaunchersGql;
  members: QueryMembersGql;
  ownProfileAccess: OwnProfileAccessEnum;
  roles: QueryRolesGql;
  settings: SettingsGql;
  version: Scalars['String']['output'];
};

export type QueryAuthorizationTokensGql = {
  __typename?: 'QueryAuthorizationTokensGQL';
  all: AuthorizationTokenConnectionGql;
  byId: AuthorizationToken;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID']['output'];
};


export type QueryAuthorizationTokensGqlAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAuthorizationTokensGqlByIdArgs = {
  idOrKey: Scalars['ID']['input'];
};

export type QueryContextsGql = {
  __typename?: 'QueryContextsGQL';
  all: ContextConnectionGql;
  byId: Context;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID']['output'];
  onlyWithLaunchers: ContextConnectionGql;
};


export type QueryContextsGqlAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryContextsGqlByIdArgs = {
  idOrKey: IdOrKeyInput;
};


export type QueryContextsGqlOnlyWithLaunchersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryLaunchersGql = {
  __typename?: 'QueryLaunchersGQL';
  all: LauncherConnectionGql;
  byId: Launcher;
  byLauncherPath: Launcher;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID']['output'];
};


export type QueryLaunchersGqlAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLaunchersGqlByIdArgs = {
  idOrKey: IdOrKeyInput;
};


export type QueryLaunchersGqlByLauncherPathArgs = {
  launcherPath: Scalars['String']['input'];
};

export type QueryMembersGql = {
  __typename?: 'QueryMembersGQL';
  all: MemberConnectionGql;
  /** Returns an existing user by internal id or by keycloak id (uuid can be internal uuid or keycloak uuid). Returns an error when the user is not found */
  byId: Member;
  /** Convenience property, will always return ## - to make Apollo and BananaCakePop happy */
  id: Scalars['ID']['output'];
  me: Member;
};


export type QueryMembersGqlAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMembersGqlByIdArgs = {
  idOrKey: IdOrKeyInput;
};

export type QueryRolesGql = {
  __typename?: 'QueryRolesGQL';
  all: RoleConnection;
  byId: RoleGql;
  /** List of roles that belongs to the client */
  clientRoles: RoleConnection;
  globalRoles: RoleConnection;
};


export type QueryRolesGqlAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRolesGqlByIdArgs = {
  clientName?: InputMaybe<Scalars['String']['input']>;
  idOrKey: Scalars['ID']['input'];
};


export type QueryRolesGqlClientRolesArgs = {
  idOrKey: Scalars['ID']['input'];
};


export type QueryRolesGqlGlobalRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type RoleConnection = {
  __typename?: 'RoleConnection';
  edges: Array<RoleEdge>;
  nodes: Array<RoleGql>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type RoleEdge = {
  __typename?: 'RoleEdge';
  cursor: Scalars['String']['output'];
  node: RoleGql;
};

export type RoleGql = {
  __typename?: 'RoleGQL';
  clientName?: Maybe<Scalars['String']['output']>;
  clientRole: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type RoleMutation = {
  __typename?: 'RoleMutation';
  remove: Scalars['Boolean']['output'];
  upsert: RoleGql;
};


export type RoleMutationRemoveArgs = {
  clientName?: InputMaybe<Scalars['String']['input']>;
  idOrKey: Scalars['ID']['input'];
};


export type RoleMutationUpsertArgs = {
  input: UpsertRoleInput;
};

/**
 * 
 * General tenant setting. Is is used to get list of available locales.
 *
 */
export type SettingsGql = {
  __typename?: 'SettingsGQL';
  /** Available keycloak themes. */
  availableKeycloakThemes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Default tenent`s date format. */
  defaultDateFormat?: Maybe<Scalars['String']['output']>;
  /** Default tenent`s locale. */
  defaultLocale?: Maybe<Scalars['String']['output']>;
  /** Default tenent`s theme. */
  defaultTheme?: Maybe<Scalars['String']['output']>;
  /** Tenant name. */
  id: Scalars['ID']['output'];
  /** Keycloak login theme settings. */
  keycloakThemeSettings?: Maybe<KeycloakThemeSettingsGql>;
  /** List of available locales supported by tenant. */
  supportedLocales: Array<Maybe<Scalars['String']['output']>>;
};

export type SettingsMutation = {
  __typename?: 'SettingsMutation';
  /** Set Content Security Policy to allow urls for theme IFrames */
  setKeycloakContentSecurityPolicy: Scalars['String']['output'];
  setTheme: KeycloakThemeSettingsGql;
  uploadTheme: Scalars['Boolean']['output'];
  upsert: SettingsGql;
};


export type SettingsMutationSetKeycloakContentSecurityPolicyArgs = {
  urls: Array<InputMaybe<Scalars['String']['input']>>;
};


export type SettingsMutationSetThemeArgs = {
  input: UpsertKeycloakThemeSettingsInput;
};


export type SettingsMutationUploadThemeArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  overrideFiles: Scalars['Boolean']['input'];
  themeName?: InputMaybe<Scalars['String']['input']>;
};


export type SettingsMutationUpsertArgs = {
  input: UpsertSettingsInput;
};

export type UpsertAuthorizationTokenInput = {
  emailBodyHtml?: InputMaybe<Scalars['String']['input']>;
  emailBodyText?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  expirationDate?: InputMaybe<Scalars['LocalDate']['input']>;
  expirationTime?: InputMaybe<Scalars['LocalTime']['input']>;
  idOrKey: IdOrKeyInput;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['Key']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  redirectionUrl?: InputMaybe<Scalars['String']['input']>;
  usageLimit?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpsertCollectionOfStringInput = {
  add?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  remove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  set?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpsertCompositeInput = {
  add?: InputMaybe<Array<InputMaybe<CompositeRoleInput>>>;
  remove?: InputMaybe<Array<InputMaybe<CompositeRoleInput>>>;
};

export type UpsertContextInput = {
  idOrKey: IdOrKeyInput;
  image?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['Key']['input']>;
  label?: InputMaybe<UpsertLangStringInput>;
  launchers?: InputMaybe<UpsertKeyedCollection2OfUpsertLauncherInput>;
  order?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpsertKeycloakThemeSettingsInput = {
  /** Url for bottom IFrame */
  bottomIFrameURL?: InputMaybe<Scalars['String']['input']>;
  /** Url for custom css file */
  customCssURL?: InputMaybe<Scalars['String']['input']>;
  /**
   * 
   * Login theme settings. 
   * Sample format :'base2','bitmedia_1', 'custom'. The values 'base', 'keycloak' and 'bitmedia' should not be used.
   *
   */
  name?: InputMaybe<Scalars['String']['input']>;
  /**
   * 
   * The name of the parent theme (if the theme is a new one and the parent is not specified - `bitmedia` value will be used).
   * The theme inherits all content from the parent theme, but overrides selected custom settings .
   * (https://www.keycloak.org/docs/latest/server_development/index.html#theme-properties)
   *
   */
  parent?: InputMaybe<Scalars['String']['input']>;
  /** Url for side IFrame */
  sideIFrameURL?: InputMaybe<Scalars['String']['input']>;
  /** Url for top IFrame */
  topIFrameURL?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertKeyedCollection2OfUpsertLauncherInput = {
  add?: InputMaybe<Array<InputMaybe<UpsertLauncherInput>>>;
  remove?: InputMaybe<Array<InputMaybe<IdOrKeyInput>>>;
  set?: InputMaybe<Array<InputMaybe<UpsertLauncherInput>>>;
};

export type UpsertLangStringInput = {
  add?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  neutral?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  set?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
};

export type UpsertLauncherInput = {
  additionalConfiguration?: InputMaybe<Scalars['String']['input']>;
  alignment?: InputMaybe<AppShellLauncherAlignment>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  contextLink?: InputMaybe<IdOrKeyInput>;
  cssClasses?: InputMaybe<Scalars['String']['input']>;
  defaultHiddenValue?: InputMaybe<Scalars['Boolean']['input']>;
  hideForRoles?: InputMaybe<UpsertCollectionOfStringInput>;
  idOrKey: IdOrKeyInput;
  image?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['Key']['input']>;
  label?: InputMaybe<UpsertLangStringInput>;
  order?: InputMaybe<Scalars['Int']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  showForRoles?: InputMaybe<UpsertCollectionOfStringInput>;
  type?: InputMaybe<AppShellLaunchType>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpsertLocationInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type UpsertMailInput = {
  additionalInfo?: InputMaybe<UpsertLangStringInput>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']['input']>;
  mainText?: InputMaybe<UpsertLangStringInput>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<UpsertLangStringInput>;
  templateType: EmailTemplateType;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpsertMemberInput = {
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  /**
   * 
   * User preferred date format. Set string to empty to remove setting. 
   * Sample format : 'dd-MM-yyyy'
   *
   */
  dateFormat?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  idOrKey: IdOrKeyInput;
  key?: InputMaybe<Scalars['Key']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  /**
   * 
   * User preferred locale. Set string to empty to remove setting. 
   * Sample locales : 'en', 'de'
   *
   */
  locale?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<UpsertLocationInput>;
  locationAddress?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<UpsertMemberRolesInput>>>;
  temporaryImageLink?: InputMaybe<Scalars['String']['input']>;
  /**
   * 
   * User preferred theme. Set string to empty to remove setting. 
   * Sample format : 'light','dark','default'
   *
   */
  theme?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpsertMemberRolesInput = {
  add?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  client?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  set?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpsertRef2Input = {
  idOrKey: IdOrKeyInput;
};

export type UpsertRoleInput = {
  /**
   * 
   * Optional client name. If value is not set, the role will be upserted on the realm level.
   *
   */
  clientName?: InputMaybe<Scalars['String']['input']>;
  composites?: InputMaybe<UpsertCompositeInput>;
  containerId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  idOrKey: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertSettingsInput = {
  /**
   * 
   * Default tenant date format settings. 
   * Sample format : 'dd-MM-yyyy'.
   *
   */
  defaultDateFormat?: InputMaybe<Scalars['String']['input']>;
  /**
   * 
   * Default tenant`s locale.
   *
   */
  defaultLocale?: InputMaybe<Scalars['String']['input']>;
  /**
   * 
   * Default tenant theme settings. 
   * Sample format :'light','dark','default'.
   *
   */
  defaultTheme?: InputMaybe<Scalars['String']['input']>;
  /**
   * 
   * List of available locales supported by tenant.
   *
   */
  locales?: InputMaybe<UpsertCollectionOfStringInput>;
};

export type ContextQueryVariables = Exact<{
  id: IdOrKeyInput;
}>;


export type ContextQuery = { __typename?: 'Query', ownProfileAccess: OwnProfileAccessEnum, context: { __typename?: 'QueryContextsGQL', id: string, byId: { __typename?: 'Context', id: string, key: any, image: string, label: { __typename?: 'LangString', id: string, value: string }, launchers: Array<{ __typename?: 'Launcher', id: string, key: any, uuid: any, type: AppShellLaunchType, image: string, baseUrl: string, path: string, alignment: AppShellLauncherAlignment, order?: number | null, cssClasses: string, additionalConfiguration: string, label: { __typename?: 'LangString', id: string, value: string } } | null> } } };

export type ContextsQueryVariables = Exact<{ [key: string]: never; }>;


export type ContextsQuery = { __typename?: 'Query', context: { __typename?: 'QueryContextsGQL', id: string, onlyWithLaunchers: { __typename?: 'ContextConnectionGQL', nodes: Array<{ __typename?: 'Context', id: string, key: any, image: string, label: { __typename?: 'LangString', id: string, value: string } }> } } };

export type ChangeLauncherHiddenFlagMutationVariables = Exact<{
  input: LauncherStateChangeInput;
}>;


export type ChangeLauncherHiddenFlagMutation = { __typename?: 'Mutation', launcher?: { __typename?: 'LauncherMutation', changeLauncherHiddenFlag: boolean } | null };

export type LaunchersQueryVariables = Exact<{ [key: string]: never; }>;


export type LaunchersQuery = { __typename?: 'Query', launchers: { __typename?: 'QueryLaunchersGQL', id: string, all: { __typename?: 'LauncherConnectionGQL', nodes: Array<{ __typename?: 'Launcher', id: string, key: any, type: AppShellLaunchType, baseUrl: string, path: string, label: { __typename?: 'LangString', id: string, value: string } }> } } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', ownProfileAccess: OwnProfileAccessEnum, members: { __typename?: 'QueryMembersGQL', id: string, me: { __typename?: 'Member', id: string, name: string, firstName: string, lastName: string, contactEmail?: string | null, imageBase64: string, dateFormat?: string | null, theme?: string | null, uuid: any, location: string, phone: string } } };

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = { __typename?: 'Query', ownProfileAccess: OwnProfileAccessEnum, context: { __typename?: 'QueryContextsGQL', id: string, all: { __typename?: 'ContextConnectionGQL', nodes: Array<{ __typename?: 'Context', id: string, order?: number | null, label: { __typename?: 'LangString', id: string, value: string }, launchers: Array<{ __typename?: 'Launcher', id: string, type: AppShellLaunchType, image: string, baseUrl: string, path: string, order?: number | null, label: { __typename?: 'LangString', id: string, value: string } } | null> }> } } };

export type Upsert_MemberMutationVariables = Exact<{
  input: UpsertMemberInput;
}>;


export type Upsert_MemberMutation = { __typename?: 'Mutation', member?: { __typename?: 'MemberMutation', upsert: { __typename?: 'Member', id: string } } | null };

export type User_By_IdQueryVariables = Exact<{
  id: IdOrKeyInput;
}>;


export type User_By_IdQuery = { __typename?: 'Query', members: { __typename?: 'QueryMembersGQL', id: string, byId: { __typename?: 'Member', id: string, name: string, firstName: string, lastName: string, contactEmail?: string | null, imageBase64: string } } };

export const ContextDocument = gql`
    query context($id: IdOrKeyInput!) {
  ownProfileAccess
  context {
    id
    byId(idOrKey: $id) {
      id
      key
      image
      label {
        id
        value
      }
      launchers {
        id
        key
        uuid
        label {
          id
          value
        }
        type
        image
        baseUrl
        path
        alignment
        order
        cssClasses
        additionalConfiguration
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContextGQL extends Apollo.Query<ContextQuery, ContextQueryVariables> {
    document = ContextDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContextsDocument = gql`
    query contexts {
  context {
    id
    onlyWithLaunchers {
      nodes {
        id
        key
        image
        label {
          id
          value
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContextsGQL extends Apollo.Query<ContextsQuery, ContextsQueryVariables> {
    document = ContextsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeLauncherHiddenFlagDocument = gql`
    mutation changeLauncherHiddenFlag($input: LauncherStateChangeInput!) {
  launcher {
    changeLauncherHiddenFlag(input: $input)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeLauncherHiddenFlagGQL extends Apollo.Mutation<ChangeLauncherHiddenFlagMutation, ChangeLauncherHiddenFlagMutationVariables> {
    document = ChangeLauncherHiddenFlagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LaunchersDocument = gql`
    query launchers {
  launchers {
    id
    all {
      nodes {
        id
        key
        type
        label {
          id
          value
        }
        baseUrl
        path
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LaunchersGQL extends Apollo.Query<LaunchersQuery, LaunchersQueryVariables> {
    document = LaunchersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MeDocument = gql`
    query me {
  ownProfileAccess
  members {
    id
    me {
      id
      name
      firstName
      lastName
      contactEmail
      imageBase64
      dateFormat
      theme
      uuid
      location
      phone
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
    document = MeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfileDocument = gql`
    query profile {
  ownProfileAccess
  context {
    id
    all {
      nodes {
        id
        order
        label {
          id
          value
        }
        launchers {
          id
          type
          image
          label {
            id
            value
          }
          baseUrl
          path
          order
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileGQL extends Apollo.Query<ProfileQuery, ProfileQueryVariables> {
    document = ProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const Upsert_MemberDocument = gql`
    mutation upsert_member($input: UpsertMemberInput!) {
  member {
    upsert(input: $input) {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class Upsert_MemberGQL extends Apollo.Mutation<Upsert_MemberMutation, Upsert_MemberMutationVariables> {
    document = Upsert_MemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const User_By_IdDocument = gql`
    query user_by_id($id: IdOrKeyInput!) {
  members {
    id
    byId(idOrKey: $id) {
      id
      name
      firstName
      lastName
      contactEmail
      imageBase64
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class User_By_IdGQL extends Apollo.Query<User_By_IdQuery, User_By_IdQueryVariables> {
    document = User_By_IdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }