<div role="banner" [ngClass]="'dx-theme-border-color'" class="main-header dx-theme-background-color">
  <div class="header-content">
    <div class="header-left-column">
      <div class="app-icon">
        <img *ngIf="currentContext?.image" [src]="currentContext.image" />
      </div>
      <app-secondary-menu *ngIf="!(isMobile$ | async)"></app-secondary-menu>
    </div>
    <div class="header-right-column">
      <div class="tertiary-menu">
        <div *ngFor="let menuItem of tertiaryMenuItems">
          <a [href]="menuItem.baseUrl" target="_blank" rel="noopener noreferrer">
            <img
              *ngIf="menuItem.image"
              [src]="menuItem.image"
              alt="{{ menuItem.text }}"
              style="width: 65%; height: auto"
            />
          </a>
        </div>
      </div>

      <div class="product-switch">
        <dx-drop-down-button
          *ngIf="contexts?.length > 1"
          [showArrowIcon]="false"
          [dropDownOptions]="{ width: 200 }"
          [icon]="productSwitchIcon"
          [items]="contexts"
          displayExpr="label.value"
          keyExpr="id"
          itemTemplate="contextItem"
          (onItemClick)="selectContext($event.itemData)"
        >
          <div *dxTemplate="let data of 'contextItem'">
            <div class="product-switch-item d-flex">
              <img *ngIf="data?.image" [src]="data?.image" />
              <div>{{ data?.label?.value }}</div>
            </div>
          </div>
        </dx-drop-down-button>
      </div>
      <div class="user-menu">
        <dx-drop-down-button
          class="user-info-menu"
          tabIndex="-1"
          displayExpr="name"
          [hint]="'user_profile_menu' | translate"
          [useSelectMode]="false"
          [icon]="userAvatar"
          [text]="'user_profile_menu' | translate"
          [items]="userInfo"
          [showArrowIcon]="false"
          [dropDownOptions]="dropDownOptions"
          itemTemplate="listItem"
          accessKey="space"
          (onItemClick)="onItemClick($event)"
        >
          <div *dxTemplate="let item of 'listItem'" class="user-info-item {{ item?.class }}">
            <div
              class="user-info-overlay"
              [attr.role]="'button'"
              [attr.aria-label]="(item.aria | translate) + ' ' + ('button' | translate)"
            >
              <div *ngIf="item.src" class="user-picture">
                <img src="{{ item.src }}" alt="{{ item.name }}" />
              </div>
              <div>
                <h4 *ngIf="item.src">{{ item.name | translate }}</h4>
                <div *ngIf="!item.src">{{ item.name | translate }}</div>
                <h6 *ngIf="item.role">{{ item.role | translate }}</h6>
              </div>
            </div>
          </div>
        </dx-drop-down-button>
      </div>
    </div>
  </div>
</div>
