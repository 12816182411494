import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppShellLauncherAlignment, AppShellLaunchType, ContextGQL, ContextsGQL } from '../../members.api.g';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { MenuHelper } from '../helpers';
import { AppContext } from '../contracts';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  constructor(private context: ContextGQL, private contextsGQL: ContextsGQL, private themeService: ThemeService) {}

  getContext(id: string): Observable<AppContext> {
    return this.context
      .fetch(
        { id: { id } },
        {
          /*notifyOnNetworkStatusChange: true, useInitialLoading: true*/
        }
      )
      .pipe(
        map(({ data, loading }) => {
          const context = data.context.byId;
          const launchers = context.launchers.map((launcher) => ({
            ...launcher,
            label: launcher.label.value,
            image: launcher.image.replace('${theme}', this.themeService.getThemeName()),
          }));
          const menuLaunchers = launchers.filter((l) => l.type !== AppShellLaunchType.Profile);
          const profileLaunchers = launchers.filter((l) => l.type === AppShellLaunchType.Profile);
          const primaryLaunchers = menuLaunchers.filter(
            (l) => l.alignment === AppShellLauncherAlignment.Primary || !l.alignment
          );
          const secondaryLaunchers = menuLaunchers.filter((l) => l.alignment === AppShellLauncherAlignment.Secondary);
          const tertiaryLaunchers = menuLaunchers.filter((l) => l.alignment === AppShellLauncherAlignment.Tertiary);
          const primaryMenuItems = MenuHelper.createMenu(primaryLaunchers);
          const secondaryMenuItems = MenuHelper.createMenu(secondaryLaunchers);
          const tertiaryMenuItems = MenuHelper.createMenu(tertiaryLaunchers);
          MenuHelper.sortLaunchers(primaryMenuItems);
          MenuHelper.sortLaunchers(secondaryMenuItems);
          const menuItems = {
            primary: primaryMenuItems.filter((element) => !(element.type === 'FOLDER' && !element.items)), //removing empty folders
            secondary: secondaryMenuItems.filter((element) => !(element.type === 'FOLDER' && !element.items)),
            tertiary: tertiaryMenuItems.filter((element) => !(element.type === 'FOLDER' && !element.items)),
          };
          return {
            id: context.id,
            key: context.key,
            image: context.image,
            label: { ...context.label },
            menuItems,
            profileItems: profileLaunchers,
            launchers,
            ownProfileAccess: data.ownProfileAccess,
          };
        })
      );
  }

  getContexts() {
    return this.contextsGQL.fetch().pipe(map((data) => data.data.context.onlyWithLaunchers.nodes));
  }
}
