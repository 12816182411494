import { error, info } from '../debug';

declare global {
  interface Window {
    __CONFIG?: {
      API_URL?: string;
      APP_VERSION?: string;
      ALLOWED_LOCALES?: string;
      APP_LOCALE?: string;
      TITLE?: string;
      DEFAULT_THEME?: string;
      IDM_ISSUER?: string;
      IDM_CLIENTID?: string;
      IDM_SCOPE?: string;
      IDM_RESPONSE_TYPE?: string;
      IDM_CLIENT_SECRET?: string;
      REQUIRE_HTTPS?: string | boolean;
      DEFAULT_DATE_FORMAT?: string;
      EMAIL_VALIDATION_PATTERN?: string;
      PASSWORD_PATTERN?: string;
      PASSWORD_TOOLTIP_CONTENT?: string;
      PASSWORD_TOOLTIP_CONTENT_DE?: string;
    };
  }
}

function appendGraphQL(url: string) {
  if (!url) {
    return url;
  }
  if (url.endsWith('/graphql')) {
    return url;
  }
  return new URL('graphql', url).toString();
}
export const apiUrl = appendGraphQL(window.__CONFIG?.API_URL || '');
if (!apiUrl) {
  error(`window.__CONFIG.API_URL not set`);
}
export const uploadUrl = new URL('../api/upload', apiUrl).toString();
export const changePassUrl = new URL('../api/changePassword', apiUrl).toString();
info(`Sending API requests to ${apiUrl}, sending uploads to ${uploadUrl}`);

export const appLocale = window.__CONFIG.APP_LOCALE;

export const allowedLocales = window.__CONFIG.ALLOWED_LOCALES ? window.__CONFIG.ALLOWED_LOCALES.split(',') : [];

export const defaultDateFormat = window.__CONFIG.DEFAULT_DATE_FORMAT;

export const emailValidationPattern = window.__CONFIG.EMAIL_VALIDATION_PATTERN;
export const passwordPattern = window.__CONFIG.PASSWORD_PATTERN;
export const passwordTooltipContent = window.__CONFIG.PASSWORD_TOOLTIP_CONTENT;
export const passwordTooltipContentDe = window.__CONFIG.PASSWORD_TOOLTIP_CONTENT_DE;
